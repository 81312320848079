import React, { useEffect, useState } from "react";
import AvatarDropDown from "../../partials/user_profile/AvatarDropDown";
import { isEmpty } from "../../../helpers/Functions";

const Avatar = ({ ...props }) => {
	const [avatar, setAvatar] = useState(null);

	useEffect(() => {
		if (!isEmpty(props.userdetail.firstname)) {
			let initials = props.userdetail.firstname.slice(0, 1) + props.userdetail.lastname.slice(0, 1);

			setAvatar(initials.toUpperCase());
			props.setUpdate(true)
		}
	}, [props.userdetail.firstname, props.userdetail.lastname]);

	return (
		<div className="theme-header-profile-picture" >
			<div className="theme-header-initials" >
				{avatar}
			</div>
		</div>
	)
}

export default Avatar;