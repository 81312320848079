import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from '../../helpers/Functions';
import { Tooltip } from '@mui/material';

const ActionIconButton = ({ ...props }) => {
	return (
		<div className={`color-primary fit-content pointer ${props.customclasses}`} 
			{...props}
			disabled={ props.issubmitting }
		>
			{
				(props.issubmitting && typeof props.disabled == 'undefined') ? (
					<CircularProgress
						className="me-2"
						sx={{
							color: "primary"
						}}
						size={20}
						thickness={7}
					/>
				):(
					(!isEmpty(props.icontooltip)) ? (
						<Tooltip title={props.icontooltip} placement='top'>
							{props.icon}
						</Tooltip>
					) 
					: (
						props.icon
					)
					
				)
			}
			<span className="ms-2 font-weight-medium color-primary pointer">{props.label}</span>
		</div>
	)
}

export default ActionIconButton;