import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import TableChartIcon from '@mui/icons-material/TableChart';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import { checkIsActive } from "../helpers/RouterHelper";
import "../../assets/content.css";
import DropDown from '../partials/DropDown';
import Avatar from "./partials/Avatar";
import AvatarDropDown from "../partials/user_profile/AvatarDropDown";
import Logo from "../partials/Logo";
import { setCurrentCapabilities } from "../../app/redux/slices/authSlice";
import RoleSelection from "./partials/RoleSelection";
import { getCompanyCapabilities, isNotStudent } from "../../helpers/Utils";
import { isEmpty } from "../../helpers/Functions";
import { getUserByToken } from "../../app/crud/person.crud";
import displayToast from "../partials/DisplayToast";
import { ROLE_MENTOR, ROLE_STUDENT } from "../../models/Constants";

const Layout = ({
	userDetails,
	userCapabilities,
	currentCapabilities,
	setCurrentCapabilities
}) => {
	const [selectedCompany, setSelectedCompany] = useState(currentCapabilities?.company?.company_id);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [userDetail, setUserDetail] = useState([]);
	const [update, setUpdate] = useState(true);
	const [loading, setLoading] = useState(false);

	let location = useLocation();

	const hasAdmin = location.pathname.startsWith('/admin-panel');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setIsDropdownOpen((prev) => !prev);
	}

	const handleOutsideClick = (event) => {
		if (event.target.closest(".theme-header-profile") === null) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick);

		return () => {
			document.removeEventListener("click", handleOutsideClick);
		}
	}, []);

	useEffect(() => {
		if (update) {
			setLoading(true);

			getUserByToken().then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					setUserDetail(response.data.data[0])
				}

				setLoading(false);
				setUpdate(false);
			})
		}
	}, [update]);

	useEffect(() => {
		let companyData = [];

		if (!isEmpty(userCapabilities)) {
			userCapabilities.map((company) => {
				companyData.push({
					key: company.company_id,
					name: company.company_name
				});
			});
		}

		setCompanyOptions(companyData);
		setUserDetail(userDetails);
	}, []);

	const getMenuIsActive = (location, url) => {
		return checkIsActive(location, url) ? "active" : "";
	}

	const handleSelectCompany = (e) => {
		let selected = getCompanyCapabilities(userCapabilities, e.target.value);
		let updatedCapabilities = { ...currentCapabilities };
		updatedCapabilities.company = selected;
	
		let roleUpdate = selected.roles[0];
	
		updatedCapabilities.role = roleUpdate.role_id;
		updatedCapabilities.role_name = roleUpdate.role_name;
		updatedCapabilities.is_limited = roleUpdate.is_limited;
	
		setSelectedCompany(e.target.value);
		setCurrentCapabilities(updatedCapabilities);
	}

	return (
		<>
			<ToastContainer />
			<div className="theme-left-container d-none d-md-flex">
				{/* left */}
				{/* <div className="theme-navigation">
				</div> */}

				<div className="theme-sub-nav">
					<Logo style={{ padding: "0 1.2rem" }} />
					{/* TODO: create component per url */}
					{/* TODO: switch statement per pathname */}
					{(currentCapabilities?.is_limited) ? (
						<>
							<ul>
								<Link to="/dashboard/course-sales">
									<li className={getMenuIsActive(location, "/admin-panel/course-sales")}>
										<BusinessIcon className="theme-sub-nav-icon" />
										<span>Dashboard</span>
									</li>
								</Link>
							</ul>
						</>
					) : (<></>)}
					{!hasAdmin && (
						<>
							<div className="theme-sub-nav-title">LIBRARIES</div>
							<ul>
								<Link to="/dashboard/trainings">
									<li
										className={`
											${getMenuIsActive(location, "/dashboard")}
											${getMenuIsActive(location, "trainings")} 
 										`}
									>
										<LocalLibraryOutlinedIcon className="theme-sub-nav-icon" />
										<span>Trainings</span>
									</li>
								</Link>
								<Link to="/dashboard/manuals">
									<li className={getMenuIsActive(location, "manuals")}>
										<LibraryBooksOutlinedIcon className="theme-sub-nav-icon" />
										<span>Manuals</span>
									</li>
								</Link>
								<Link to="/dashboard/policies">
									<li className={getMenuIsActive(location, "policies")}>
										<PolicyOutlinedIcon className="theme-sub-nav-icon" />
										<span>Policies</span>
									</li>
								</Link>
							</ul>
							{
								(isNotStudent(currentCapabilities.role)) && (
									<ul>
										<Link to="/dashboard/settings">
											<li className={getMenuIsActive(location, "settings")}>
												<SettingsIcon className="theme-sub-nav-icon" />
												<span>Settings</span>
											</li>
										</Link>
									</ul>
								)
							}
						</>
					)
					}
					{hasAdmin && (
						<>
							<div className="theme-sub-nav-title">ADMIN</div>
							<ul>
								<Link to="/admin-panel/companies">
									<li className={getMenuIsActive(location, "/admin-panel/companies")}>
										<BusinessIcon className="theme-sub-nav-icon" />
										<span>Companies</span>
									</li>
								</Link>
								<Link to="/admin-panel/settings">
									<li className={getMenuIsActive(location, "/admin-panel/settings")}>
										<SettingsIcon className="theme-sub-nav-icon" />
										<span>Settings</span>
									</li>
								</Link>
							</ul>
						</>
					)
					}
				</div>
			</div>
			<div className="theme-right-container d-none d-md-flex" id="main-panel-container">
				{/* right */}
				<div className="w-100">
					<div className="theme-header color-quinary">
						{/* top */}
						<div className="theme-header-breadcrumbs">
							{/* top left */}
							<span className="me-2 font-size-medium font-weight-medium" style={{ lineHeight: "40px" }}>
								Organization:
							</span>
							<DropDown
								value={selectedCompany}
								options={companyOptions}
								onChange={handleSelectCompany}
								small="true"
							/>
						</div>
						<div className="theme-header-profile">
							{/* top right */}
							<RoleSelection />
							{
								userDetail && (
									<div className="theme-header-profile-container" onClick={toggleDropdown} >
										<div className="theme-header-profile-name font-weight-medium">
											{userDetail.firstname ? `Hi ${userDetail.firstname}!` : ""}
										</div>
										<Avatar
											userdetail={userDetail}
											setUpdate={setUpdate}
										/>
										{isDropdownOpen && (
											<>
												<AvatarDropDown
													userdetail={userDetail}
													setUpdate={setUpdate}
												/>
											</>
										)}
									</div>
								)
							}
						</div>
					</div>
					<div className="theme-content">
						{/* main content */}
						<Outlet />
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state, ownProps = {}) => {
	let data = {
		userDetails: state.auth.user_details,
		userCapabilities: state.auth.user_capabilities,
		currentCapabilities: state.auth.current_capabilities
	}

	return data;
}

const mapDispatchToProps = {
	setCurrentCapabilities
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);