import React, { useEffect, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { useLocation } from "react-router-dom";
import { isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { getRatingsResult, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import BaseTextField from "../../BaseTextField";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Button } from "react-bootstrap";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const RatingsContent = ({ ratings, ...props}) => {

	const submitResponse = () => {
		const hasUnfinishedRatings = props.answer.some(answer => {
			if (answer.value == 0) {
				displayToast("info", "Please complete the ratings");
				return true;
			}
			return false;
		});
	
		if (hasUnfinishedRatings) {
			return;
		}
		else {
			props.submitStudentResponse();
		}
	}

	return(<>
		{
			props.answerSent ? (<>
				<center>
					<div className="d-block mb-2 py-3 px-1">
						<TextView className="d-block font-size-xl">
							Thank you for your valuable feedback.
						</TextView>
						<TextView className="d-block font-size-medium color-secondary">
							We sincerely appreciate your ratings and comments which will help us improve and make things better. Your inputs truly matter to us.
						</TextView>
					</div>
				</center>
			</>) : (<>
				<div className="d-block mb-2">
					<TextView className="d-block font-size-medium font-weight-bold">
						{ props.element?.description }
					</TextView>
				</div>

				<div className="mb-3">
					{
						props.element.additional_attachments.map((criteria, i) => (
							<div className="d-block mb-2" key={i}>
								<TextView className="mb-1">{criteria?.description}</TextView>
								<div className="ms-2 ps-3 radio-button-group">
								{ratings.map((rating) => (
									<div key={rating.value} className="mb-1 me-3 d-flex">
										<input
											type="radio"
											id={`rating-${i}-${rating.value}`}
											name={`rating-${i}`}
											value={rating.value}
											checked={props.answer?.[i]?.value === rating.value}
											onChange={(e) => {
												let newAnswers = [...props.answer];
												let ratingIndex = { ...newAnswers[i] };

												ratingIndex.value = parseInt(e.target.value);
												newAnswers[i] = ratingIndex;
												props.setAnswer(newAnswers);
											}}
											className="form-check-input me-2"
										/>
										<TextView htmlFor={`rating-${i}-${rating.value}`} className="form-check-label color-secondary">
											{rating.label}
										</TextView>
									</div>
								))}
								</div>
							</div>
						))
					}
				</div>
				
				{(props.element?.context?.require_explanation) &&  (<>
					<div className="d-flex font-size-xm font-weight-bold"> 
						{props.element?.context?.explanation_description ? props.element?.context?.explanation_description : "Please explain your answer"}
					</div>
					<BaseTextField
						customclasses="mb-3 mt-1"
						placeholder="Please explain your answer"
						value={props.explanation}
						multiline
						minRows={2}
						maxRows={2}
						rows={2}
						onChange={(e) => {
							props.setExplanation(e.target.value)
						}}
					/>
				</>)}

				<BaseButton 
					variant={isEmpty(props.quickAccess) ? (isNotStudent(props.userCapabilities.role) ? "green" : "primary") : "primary"}
					customclasses="d-block mx-auto my-4"
					onClick={() => {
						if(isEmpty(props.quickAccess)) {
							if(isNotStudent(props.userCapabilities.role)) {
								props.getRatingsResult();
							}
							else {
								submitResponse();
							}
						}
						else {
							submitResponse();
						}
					}}
				>
					{isEmpty(props.quickAccess) ? (isNotStudent(props.userCapabilities.role) ? `View Results` : `Submit`) : "Submit"}
				</BaseButton>	
			</>)
		}
		

		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const ResultDialog = ({ ...props }) => {
	const [expIndex , setExpIndex] = useState(0);
	
	useEffect(() => {
		if(!isEmpty(props.content?.explanations)){
			setExpIndex(0)
		}
	},[props.content])

	const calculateRating = (number) =>{
		const roundedNumber = Math.round(number);
		const rating = props.ratings.find(r => r.value === roundedNumber);
		
		return rating ? rating.label : "Invalid rating";
	}
	return (
		<Dialog 
			open={props.open} 
			fullWidth
			maxWidth="md"
		>
			<DialogTitle className="font-size-large">Ratings Results</DialogTitle>
			<Divider />
			<DialogContent style={{ whiteSpace: 'pre-line' }}>
				<TextView className="font-size-medium font-weight-bold d-block mb-2">{props.element.description}</TextView>
				{
					!isEmpty(props.content.results) ? (<>
						<div className="ratings-card">
							<TextView className="font-size-medium d-block mb-2">Total Responses: {props.content.results[0].responses}</TextView>
							<div className="px-2">
								{
									props.content.results.map(result => (
										<div className="d-block mb-2">
											<TextView className="font-size-medium color-primary">{result.description} :</TextView>
											<TextView className="font-size-medium ms-3">{calculateRating(result.average)} ( {result.average} / 5 )</TextView>
										</div>
									))
								}
							</div>
						</div>

						<div className="ratings-card">
							<TextView className="font-size-medium d-block mb-2">{props.element.context.explanation_description}</TextView>
							<div className="px-2">
								{
									!isEmpty(props.content.explanations) ? (<>
										<div className="mb-2">
											<div className="d-block mb-2">
												<TextView className="d-block font-size-medium color-primary">{props.content.explanations[expIndex]?.name} :</TextView>
												<TextView className="d-block font-size-medium ms-3">{props.content.explanations[expIndex]?.explanation}</TextView>
											</div>
											<div className="d-flex">
												{
													expIndex > 0 ? (
														<KeyboardDoubleArrowLeftIcon
															className='font-size-xl color-primary me-auto'
															style={{marginTop: '0.75rem', cursor: 'pointer'}}
															onClick={() => {setExpIndex(expIndex - 1)}}
														/>
													) : null
												}
												{
													expIndex < props.content.explanations.length - 1 ? (
														<KeyboardDoubleArrowRightIcon
															className='font-size-xl color-primary ms-auto'
															style={{marginTop: '0.75rem', cursor: 'pointer'}}
															onClick={() => {setExpIndex(expIndex + 1)}}
														/>
													) : null
												}
											</div>
										</div>
									</>) : (<TextView>No Comments has been Found.</TextView>)
								}
							</div>
						</div>
					</>) :
					(<TextView>No Responses Yet. Please be patient.</TextView>)
				}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.onClose?.(false)
					}}
					color="primary"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const RatingsElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);
	const [answerSent, setAnswerSent] = useState(false)
	const [ratingsAnswers, setRatingAnswers] = useState([]);
	const [explanation, setExplanation] = useState("")

	const ratings = [
		{ "value": 1, "label": "Inadequate" },
		{ "value": 2, "label": "Fair" },
		{ "value": 3, "label": "Good" },
		{ "value": 4, "label": "Very Good" },
		{ "value": 5, "label": "Excellent" }
	]

	useEffect(() => {
		let answers = []

		props.element.additional_attachments.map(criteria => {
			answers.push({"id" : criteria.id, "value" : 0})
		})

		setRatingAnswers(answers);
	}, [props.element])

	const submitStudentResponse = () => {
		const student_response = {
			context : {
				require_explanation : props.element?.context?.require_explanation,
				explanation_description : explanation
			},
			answers: ratingsAnswers
		}

		let params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element?.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props?.element?.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response
		}

		if(isEmpty(props.currentCapabilities.role) && !isEmpty(props.quickAccess)){
			params.email = props.quickAccess.email;
			params.firstname = props.quickAccess.name.firstname;
			params.lastname = props.quickAccess.name.lastname;

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Answer Submitted");
					setAnswerSent(true)
				}
			})
		}
		else if(!isNotStudent(props.currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Answer Submitted");
					setAnswerSent(true)
				}
			})
		}
	}

	const [ratingsResult, setRatingsResult] = useState({});
	const [openResults, setOpenResults] = useState(false);

	const getElementRatingsResult = () => {
		getRatingsResult(props.element.element_id).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setRatingsResult(response.data.data[0])
				setOpenResults(true)
			}
		})
	}

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<ResultDialog
					element={props.element}
					open={openResults}
					onClose={setOpenResults}
					content={ratingsResult}
					ratings={ratings}
				/>
				<RatingsContent 
					ratings={ratings}
					element={props.element}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
					answer={ratingsAnswers}
					answerSent={answerSent}
					explanation={explanation}
					setAnswer={setRatingAnswers}
					setExplanation={setExplanation}
					submitStudentResponse={submitStudentResponse}
					getRatingsResult={getElementRatingsResult}
				/>
			</LessonAccordion>
		) : (
			<RatingsContent 
				ratings={ratings}
				element={props.element}
				userCapabilities={props.currentCapabilities}
				answer={ratingsAnswers}
				answerSent={answerSent}
				explanation={explanation}
				setAnswer={setRatingAnswers}
				setExplanation={setExplanation}
				submitStudentResponse={submitStudentResponse}
				quickAccess={props.quickAccess}
			/>
		)
		
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		quickAccess: state.quickAccess
	}

	return data;
}

export default connect(mapStateToProps)(RatingsElement);